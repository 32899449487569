import styled from "styled-components"

import { formatUTCStringDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { useFetchPlanRenewalEstimate } from "src/data/billing/queries/billingQueries"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { MInfo } from "src/ui/Info/MInfo"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PlanRenewal({
  userId,
  date,
}: {
  userId: string
  date?: string
}) {
  const fetchPlanRenewalEstimate = useFetchPlanRenewalEstimate({
    userId: userId,
  })

  const { t, langKeys } = useTranslate()

  const { estimateBreakdown } = useFlags()

  if (fetchPlanRenewalEstimate.isLoading || fetchPlanRenewalEstimate.isError) {
    return null
  }

  const planRenewalEstimate = fetchPlanRenewalEstimate.data

  const formatedDate = formatUTCStringDate(date)

  if (estimateBreakdown) {
    return <PlanRenewalWithEstimateBreakdown userId={userId} date={date} />
  }

  return (
    <MCard border boxShadow={false}>
      <MText variant="subtitle" marginBottom={spacing.XS}>
        {t(langKeys.subscription_renewal)} {formatedDate}
      </MText>
      <PlanRenewalEstimate>
        <PlanRenewalEstimateDescription>
          <MText>{t(langKeys.plan_renewal_estimate_description)} </MText>
          <MInfo
            content={t(langKeys.plan_renewal_estimate_description_tooltip)}
            iconProps={{
              width: 20,
              height: 20,
            }}
            tooltipProps={{
              style: {
                // This makes sure the wrapping div is as high as it needs rather than how high as the container, making the icon centered
                display: "flex",
              },
            }}
          />
        </PlanRenewalEstimateDescription>
        <MText>{planRenewalEstimate.formatted_renewal_amount}</MText>
      </PlanRenewalEstimate>
    </MCard>
  )
}

function PlanRenewalWithEstimateBreakdown({
  userId,
  date,
}: {
  userId: string
  date?: string
}) {
  const fetchPlanRenewalEstimate = useFetchPlanRenewalEstimate({
    userId: userId,
  })

  const { t, langKeys } = useTranslate()

  if (fetchPlanRenewalEstimate.isLoading || fetchPlanRenewalEstimate.isError) {
    return null
  }

  const planRenewalEstimate = fetchPlanRenewalEstimate.data

  const formatedDate = formatUTCStringDate(date)

  return (
    <MCard border boxShadow={false}>
      <PlanRenewalEstimate>
        <div>
          <MText variant="subtitle" marginBottom={spacing.XS}>
            {t(langKeys.subscription_renewal)} {formatedDate}
          </MText>
          <PlanRenewalEstimateDescription>
            <MText>
              {t(langKeys.plan_renewal_estimate_description)}:{" "}
              {planRenewalEstimate.formatted_renewal_amount}{" "}
            </MText>
            <MInfo
              content={t(langKeys.plan_renewal_estimate_description_tooltip)}
              iconProps={{
                width: 20,
                height: 20,
              }}
              tooltipProps={{
                style: {
                  // This makes sure the wrapping div is as high as it needs rather than how high as the container, making the icon centered
                  display: "flex",
                },
              }}
            />
          </PlanRenewalEstimateDescription>
        </div>
        <div>WIll be implemented in GT-1053</div>
      </PlanRenewalEstimate>
    </MCard>
  )
}

const PlanRenewalEstimate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const PlanRenewalEstimateDescription = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS2};
`
